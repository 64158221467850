import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";

function ELibrary() {
  const { t } = useTranslation();

  let title = {
    caption: t("pages.e_library.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.e_library.title")} titleKey="pages.e_library.title" isWidthSmall={true}>
        <div data-key="pages.e_library.text" dangerouslySetInnerHTML={{ __html: t("pages.e_library.text") }}></div>
      </Page>
    </MainLayout>
  );
}

export default ELibrary;
