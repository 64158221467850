import React, { useEffect } from "react";
import { EyeIcon, EyeCloseIcon } from "../utils/svg";
import { useDispatch, useSelector } from "react-redux";
import { ACCESSIBILITY_OPEN_CLASS } from "../../constants/global";
import { fetchAccessibility, removeAccessibilitySize, removeAccessibilityColor } from "../../actions/global";

const SwitchAccessibility = () => {
  const dispatch = useDispatch();

  const { accessibility } = useSelector((state) => state.global);

  useEffect(() => {
    if (accessibility) {
      !document.body.classList.contains(ACCESSIBILITY_OPEN_CLASS) && document.body.classList.add(ACCESSIBILITY_OPEN_CLASS);
    } else {
      if (document.body.classList.contains(ACCESSIBILITY_OPEN_CLASS)) {
        const classes = document.body.className.split(" ").filter((c) => !c.startsWith("accessibility-"));
        document.body.className = classes.join(" ").trim();
        removeAccessibilitySize();
        removeAccessibilityColor();
      }
    }
  }, [accessibility]);

  const toggleSwitch = (e) => {
    e.preventDefault();
    dispatch(fetchAccessibility(!accessibility));
  };

  return (
    <a href="/accessibility" className="switch switch-accessibility switch-style" onClick={toggleSwitch}>
      <span className="switch__handle switch-style__handle">
        <EyeCloseIcon />
        <EyeIcon />
      </span>
      <span className="switch-style__item">
        <EyeCloseIcon />
      </span>
      <span className="switch-style__item">
        <EyeIcon />
      </span>
    </a>
  );
};

export default SwitchAccessibility;
