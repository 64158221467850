import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPresskit } from "../../actions/presskit";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import cn from "classnames";

import { Title, Button } from "@vshp/ui-components";
import partnersListBg from "../../images/partners.jpg";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function Presskit() {
  const dispatch = useDispatch();
  const { presskit, presskitAreLoading } = useSelector((state) => state.presskit);
  let content = null;
  let title = null;
  const { t } = useTranslation();
  let locale = i18n.language;

  useEffect(() => {
    dispatch(fetchPresskit(locale));
  }, [dispatch, locale]);

  if (presskitAreLoading) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  title = {
    caption: t("pages.presskit.title"),
  };

  const renderTileItem = (photo, name, files, url, i) => (
    <div className="tile__item" key={i}>
      <div className="tile__item-pic">
        {url && url.length > 0 ? (
          <a href={url} className="" target="_blank" rel="noopener noreferrer">
            <img src={photo} alt={name} />
          </a>
        ) : (
          <img src={photo} alt={name} />
        )}

        {files.length > 0 && (url === null || url.length <= 0) && (
          <div className="tile__item-pic-backdrop">
            <div className="tile__item-pic-download-label" data-key="presskit.download_format">
              {t("presskit.download_format")}
            </div>
            <div className="tile__item-pic-download-btns">
              {files.map((file, key) => (
                <Button href={file.url} key={key} className="tile__item-pic-download-btn" size="small" target="_blank" rel="noopener noreferrer">
                  {file.name}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>

      {url && url.length > 0 ? (
        <div className="tile__item-title">
          <a href={url} className="tile__item-title" target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </div>
      ) : (
        <div className="tile__item-title">{name}</div>
      )}
    </div>
  );

  return (
    <MainLayout title={title}>
      <div className="page__header">
        <img className="page__header-cover" src={partnersListBg} alt="" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="pages.presskit.title">
            {t("pages.presskit.title")}
          </Title>
          <div className="page__header-intro" data-key="pages.presskit.intro" dangerouslySetInnerHTML={{ __html: t("pages.presskit.intro") }}></div>
        </div>
      </div>
      <div className="page__blocks">
        {presskit.length > 0 &&
          presskit.map((group, key) => (
            <div  className={`page__block ${presskit.length - 1 !== key ? "page__block_border" : ""}`} key={key}>
              <div className="page__block-body_small">
                {group.attributes.name.length > 0 && <Title type="h2">{group.attributes.name}</Title>}
                {group.attributes.description.length > 0 && (
                  <div className="page__text page__block-text wysiwyg">
                    <p>{group.attributes.description}</p>
                  </div>
                )}
              </div>

              <div
                className={cn("tile", {
                  tile_three: group.attributes.format === "x3",
                })}
              >
                {group.attributes.items.length > 0 && group.attributes.items.map((item, i) => renderTileItem(item.photo, item.name, item.files, item.url, i))}
              </div>
            </div>
          ))}
      </div>
    </MainLayout>
  );
}

export default Presskit;
