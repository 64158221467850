import { combineReducers } from "redux";
import { RESPONSE_ERRORS, RESPONSE_SUCCESS, CURRENT_LANGUAGE, ACCESSIBILITY, ACCESSIBILITY_ACTIVE_SIZE, ACCESSIBILITY_ACTIVE_COLOR } from "../constants/global";

export function responseErrors(state = [], action) {
  switch (action.type) {
    case RESPONSE_ERRORS:
      return action.errors;
    default:
      return state;
  }
}

export function responseSuccess(state = [], action) {
  switch (action.type) {
    case RESPONSE_SUCCESS:
      return action.success;
    default:
      return state;
  }
}

export function currentLanguage(state = "", action) {
  switch (action.type) {
    case CURRENT_LANGUAGE:
      return action.lang;
    default:
      return state;
  }
}

export function accessibility(state = false, action) {
  switch (action.type) {
    case ACCESSIBILITY:
      return action.bool;
    default:
      return state;
  }
}

export function accessibilityActiveSize(state = 0, action) {
  switch (action.type) {
    case ACCESSIBILITY_ACTIVE_SIZE:
      return action.number;
    default:
      return state;
  }
}

export function accessibilityActiveColor(state = 0, action) {
  switch (action.type) {
    case ACCESSIBILITY_ACTIVE_COLOR:
      return action.number;
    default:
      return state;
  }
}

export const globalReducers = () =>
  combineReducers({
    responseErrors: responseErrors,
    responseSuccess: responseSuccess,
    currentLanguage: currentLanguage,
    accessibility: accessibility,
    accessibilityActiveSize: accessibilityActiveSize,
    accessibilityActiveColor: accessibilityActiveColor,
  });
