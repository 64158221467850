import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import FooterConsult from "./footer/consult";
import FooterContacts from "./footer/contacts";
import WidgetBtnConsult from "./common/WidgetBtnConsult";
import { NAVIGATION_FOOTER } from "../../constants/global";
import { URL } from "../../config/config";
import { FacebookIcon, InstagramIcon, VkIcon, YoutubeIcon, PlayMarketIcon, AppStoreIcon, TelegramIcon, TwitterIcon, YandexZenIcon, OkIcon } from "../utils/svg";
import { isLoggedIn } from "../../actions/global";
import { Button } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { languagePathUrl } from "../utils/languagePathUrl";
import SwitchTheme from "../shared/Switch";
import SwitchAccessibility from "../shared/SwitchAccessibility";

function Footer() {
  const loggedIn = isLoggedIn();
  const { programsDegrees, programsDegreesAreLoading } = useSelector((state) => state.programs);
  const { settings, settingsAreLoading } = useSelector((state) => state.settings);
  const { currentLanguage } = useSelector((state) => state.global);
  const { t } = useTranslation();

  return (
    <>
      <footer className="footer">
        <div className="footer__nav-wrapper">
          <nav className="footer__nav">
            <div className="footer__nav-title" data-key="ui.menu_information">
              {t("ui.menu_information")}
            </div>
            {NAVIGATION_FOOTER.map(({ name, url }, ikey) => (
              <Link className="footer__nav-item" to={`${languagePathUrl(currentLanguage)}${url}`} key={ikey} rel="noreferrer" data-key={name}>
                {t(name)}
              </Link>
            ))}
          </nav>

          {!programsDegreesAreLoading && programsDegrees.length > 0 ? (
            <nav className="footer__nav">
              <div className="footer__nav-title" data-key="ui.degrees">
                {t("ui.degrees")}
              </div>
              {programsDegrees.map((item, ikey) => (
                <Link to={`${languagePathUrl(currentLanguage)}/programs/${item.attributes.slug}`} key={ikey} className="footer__nav-item">
                  {item.attributes.title}
                </Link>
              ))}
            </nav>
          ) : null}

          <div className="footer__nav-col">
            <nav className="footer__nav">
              <div className="footer__nav-title" data-key="ui.account">
                {t("ui.account")}
              </div>

              {loggedIn ? (
                <div className="footer__nav-sub-wrapper">
                  <a href={`${URL}/profile`} className="footer__nav-item" data-key="ui.account">
                    {t("ui.account")}
                  </a>
                  <a href={`${URL}/sign_out`} className="footer__nav-item" data-key="ui.logout">
                    {t("ui.logout")}
                  </a>
                </div>
              ) : (
                <div className="footer__nav-sub-wrapper">
                  <a href={`${URL}/register`} className="footer__nav-item" data-key="ui.signup">
                    {t("ui.signup")}
                  </a>
                  <a href={`${URL}/sign_in`} className="footer__nav-item" data-key="ui.login">
                    {t("ui.login")}
                  </a>
                </div>
              )}

              {loggedIn && !settingsAreLoading && settings.telegram_chat !== null && (
                <div className="footer__nav-section">
                  <div className="footer__nav-title" data-key="footer.student_chat_title" dangerouslySetInnerHTML={{ __html: t("footer.student_chat_title") }}></div>
                  {!settingsAreLoading && settings.telegram_chat !== null && (
                    <Button className="footer__socials-item" size="small" corners="square" href={settings.telegram_chat} target="_blank" rel="noopener noreferrer">
                      <TelegramIcon />
                    </Button>
                  )}
                </div>
              )}

              {!settingsAreLoading && (settings.mobile_app_google_link !== null || settings.mobile_app_apple_link !== null) && (
                <div className="footer__nav-section footer__nav-apps">
                  <div className="footer__nav-title" data-key="ui.apps">
                    {t("ui.apps")}
                  </div>
                  {!settingsAreLoading && (
                    <>
                      {settings.mobile_app_google_link !== null && (
                        <a href={settings.mobile_app_google_link} target="_blank" rel="noreferrer" className="footer__nav-apps-link">
                          <PlayMarketIcon className="footer__nav-apps-icon footer__nav-apps-icon_pm" />
                        </a>
                      )}
                      {settings.mobile_app_apple_link !== null && (
                        <a href={settings.mobile_app_apple_link} target="_blank" rel="noreferrer" className="footer__nav-apps-link">
                          <AppStoreIcon className="footer__nav-apps-icon" />
                        </a>
                      )}
                    </>
                  )}
                </div>
              )}
            </nav>

            <nav className="footer__nav">
              <div className="footer__nav-title" data-key="ui.dark_theme">
                {t("ui.dark_theme")}
              </div>
              <SwitchTheme />
            </nav>

            <nav className="footer__nav">
              <div className="footer__nav-title" data-key="ui.accessibility.menu_title">
                {t("ui.accessibility.menu_title")}
              </div>
              <SwitchAccessibility />
            </nav>
          </div>
        </div>
        <div className="footer__wrapper">
          {loggedIn ? <FooterConsult /> : <FooterContacts />}

          <div className="footer__socials">
            {!settingsAreLoading && settings.social_links.length > 0 && (
              <div className="footer__socials-inner">
                <span className="footer__socials-title" data-key="ui.socials">
                  {t("ui.socials")}
                </span>
                {settings.fb_link !== null && (
                  <Button className="footer__socials-item" size="small" corners="square" href={settings.fb_link} target="_blank" rel="noopener noreferrer">
                    <FacebookIcon />
                  </Button>
                )}
                {settings.tw_link !== null && (
                  <Button className="footer__socials-item" size="small" corners="square" href={settings.tw_link} target="_blank" rel="noopener noreferrer">
                    <TwitterIcon />
                  </Button>
                )}
                {settings.in_link !== null && (
                  <Button className="footer__socials-item" size="small" corners="square" href={settings.in_link} target="_blank" rel="noopener noreferrer">
                    <InstagramIcon />
                  </Button>
                )}
                {settings.yt_link !== null && (
                  <Button className="footer__socials-item" size="small" corners="square" href={settings.yt_link} target="_blank" rel="noopener noreferrer">
                    <YoutubeIcon />
                  </Button>
                )}
                {settings.tg_link !== null && (
                  <Button className="footer__socials-item" size="small" corners="square" href={settings.tg_link} target="_blank" rel="noopener noreferrer">
                    <TelegramIcon />
                  </Button>
                )}
                {settings.zn_link !== null && (
                  <Button className="footer__socials-item" size="small" corners="square" href={settings.zn_link} target="_blank" rel="noopener noreferrer">
                    <YandexZenIcon />
                  </Button>
                )}

                {settings.vk_link !== null && (
                  <Button className="footer__socials-item" size="small" corners="square" href={settings.vk_link} target="_blank" rel="noopener noreferrer">
                    <VkIcon />
                  </Button>
                )}
                {settings.ok_link !== null && (
                  <Button className="footer__socials-item" size="small" corners="square" href={settings.ok_link} target="_blank" rel="noopener noreferrer">
                    <OkIcon />
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="footer__copyright text-center">
          <p data-key="footer" dangerouslySetInnerHTML={{ __html: t("footer") }}></p>
          <p data-key="footer.organization_name">
            &copy; {new Date().getUTCFullYear()}, {t("footer.organization_name")}.
          </p>
        </div>
      </footer>
      <WidgetBtnConsult />
    </>
  );
}

export default Footer;
