import { useEffect } from "react";
import MainLayout from "../layout";
import { Title, Section } from "@vshp/ui-components";
import searchPageBg from "../../images/hero.jpg";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

function SearchPage() {
  const { t } = useTranslation();
  // let [searchParams] = useSearchParams();
  let locale = i18next.language;
  // let content = null;
  let title = null;
  // const dispatch = useDispatch();
  // const { searchResult, searchResultAreLoading } = useSelector((state) => state.search);

  useEffect(() => {
    const script = document.createElement("script");
    document.head.append(script);
    script.src = "https://cse.google.com/cse.js?cx=3651e00739f7b4299";
  }, []);

  // useEffect(() => {
  //   if (searchParams.get("q")) {
  //     dispatch(
  //       fetchSearchResult({
  //         query: searchParams.get("q"),
  //         locale: locale,
  //       })
  //     );
  //   }
  // }, [dispatch, searchParams, locale]);

  // if (searchParams.get("q") !== null && searchResultAreLoading) {
  //   content = <Loading contentPreloader />;
  //   title = {
  //     caption: t("ui.loading"),
  //   };
  //   return <MainLayout title={title}>{content}</MainLayout>;
  // }

  title = {
    caption: t("pages.search.title"),
  };

  // let programs = searchResult.programs.data;
  // let posts = searchResult.posts.data;
  // let faq = searchResult.faq.data;

  return (
    <MainLayout title={title} global={false}>
      <div className="page__header">
        <img className="page__header-cover" src={searchPageBg} alt={t("pages.search.title")} />
        <div className="page__header-body page__header-body_center page__header-body_theme">
          <Title decor={false} className="page__header-title" data-key="pages.search.title">
            {t("pages.search.title")}
          </Title>
        </div>
      </div>
      <div className="page__blocks">
        <Section>
          <div className="gcse-search" data-lr={`lang_${locale}`}></div>
        </Section>

        {/* {!searchResultAreLoading &&
          ((faq.length <= 0 && posts.length <= 0 && programs.length <= 0) || searchParams.get("q") === null ? (
            <Section className="wysiwyg" data-key="pages.search.not_found">
              {t("pages.search.not_found")}
            </Section>
          ) : (
            <>
              {programs.length > 0 && (
                <Section>
                  <Title data-key="pages.search.programs_title">{t("pages.search.programs_title")}</Title>
                  <div className="programs-feed">{renderProgramCard(programs)}</div>
                </Section>
              )}
              {posts.length > 0 && (
                <Section>
                  <Title data-key="pages.search.posts_title">{t("pages.search.posts_title")}</Title>
                  <div className="posts-feed__wrapper">
                    {posts.map((post, id) => (
                      <PostCard item={post} key={id} />
                    ))}
                  </div>
                </Section>
              )}
              {faq.length > 0 && (
                <Section>
                  <Title data-key="pages.search.faq_title">{t("pages.search.faq_title")}</Title>
                  <Collapse>
                    {faq.map((item, key) => (
                      <Collapse.Item header={item.attributes.title} content={item.attributes.content} key={key} />
                    ))}
                  </Collapse>
                </Section>
              )}
            </>
          ))} */}
      </div>
    </MainLayout>
  );
}

export default SearchPage;
