import React, { useEffect } from "react";
import { MoonIcon, SunIcon } from "../utils/svg";
import { THEME_DARK_CLASS } from "../../constants/global";
import { getTheme, setTheme, removeTheme } from "../../actions/global";
import { useSelector } from "react-redux";

const SwitchTheme = () => {
  let stateTheme = getTheme();
  const { accessibility } = useSelector((state) => state.global);

  useEffect(() => {
    if (stateTheme !== null) {
      !document.body.classList.contains(THEME_DARK_CLASS) && document.body.classList.add(THEME_DARK_CLASS);
    }
  }, []);

  useEffect(() => {
    if (accessibility) {
      if (document.body.classList.contains(THEME_DARK_CLASS)) {
        const classes = document.body.className.split(" ").filter((c) => !c.startsWith("theme"));
        document.body.className = classes.join(" ").trim();
      }
    } else {
      if (stateTheme !== null) {
        !document.body.classList.contains(THEME_DARK_CLASS) && document.body.classList.add(THEME_DARK_CLASS);
      }
    }
  }, [accessibility]);

  const toggleSwitch = () => {
    if (document.body.matches(`.${THEME_DARK_CLASS}`)) {
      const classes = document.body.className.split(" ").filter((c) => !c.startsWith("theme"));
      document.body.className = classes.join(" ").trim();
      removeTheme();
    } else {
      document.body.classList.add(THEME_DARK_CLASS);
      setTheme();
    }
  };

  return (
    <button type="button" className="switch switch-style switch-theme" onClick={toggleSwitch} disabled={accessibility}>
      <span className="switch__handle switch-style__handle">
        <SunIcon />
        <MoonIcon />
      </span>
      <span className="switch-style__item">
        <SunIcon />
      </span>
      <span className="switch-style__item">
        <MoonIcon />
      </span>
    </button>
  );
};

export default SwitchTheme;
