import { useState } from "react";
import { SliderHorizontalIcon } from "../utils/svg";
import i18n from "i18next";

function ModuleLeads() {
  const [backdropToggled, setBackdropToggled] = useState(true);
  let locale = i18n.language;

  const handleBackdropClick = (e) => {
    setBackdropToggled(false);
  };

  return (
    <div className="stat__table">
      {backdropToggled && (
        <>
          <div className="stat__table-icon">
            <SliderHorizontalIcon />
          </div>
          <div className="stat__table-backdrop" onClick={handleBackdropClick}></div>
        </>
      )}

      <div className="stat__table-data">
        <div className="stat__table-data-container">
          {locale !== "ru" ? (
            <table>
              <thead>
                <tr>
                  <th colSpan="3">Teaching Staff</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Kirill Alekseev</td>
                  <td>Associate Professor</td>
                  <td>k.alexeev@siil.ch</td>
                </tr>
                <tr>
                  <td>Nika Almar</td>
                  <td>Associate Professor</td>
                  <td>n.almar@siil.ch</td>
                </tr>
                <tr>
                  <td>Marina Andreeva</td>
                  <td>Lecturer</td>
                  <td>m.andreeva@siil.ch</td>
                </tr>
                <tr>
                  <td>Egor Arsenikhin</td>
                  <td>Lecturer</td>
                  <td>e.arseniknin@siil.ch</td>
                </tr>
                <tr>
                  <td>Vladimir Baturin</td>
                  <td>Professor Emeritus</td>
                  <td>v.baturin@siil.ch</td>
                </tr>
                <tr>
                  <td>Irina Baturina</td>
                  <td>Professor</td>
                  <td>i.baturina@siil.ch</td>
                </tr>
                <tr>
                  <td>Tatyana Belikova</td>
                  <td>Associate Professor</td>
                  <td>t.belikova@siil.ch</td>
                </tr>
                <tr>
                  <td>Sergei Bogachev</td>
                  <td>Professor</td>
                  <td>s.bogachev@siil.ch</td>
                </tr>
                <tr>
                  <td>Sergey Evseev</td>
                  <td>Lecturer</td>
                  <td>s.evseev@siil.ch</td>
                </tr>
                <tr>
                  <td>Ivan Glazkov</td>
                  <td>Lecturer</td>
                  <td>i.glazkov@siil.ch</td>
                </tr>
                <tr>
                  <td>Denis Gnyrya</td>
                  <td>Lecturer</td>
                  <td>d.gnyrya@siil.ch</td>
                </tr>
                <tr>
                  <td>Andrey Ilyin</td>
                  <td>Professor</td>
                  <td>a.ilyin@siil.ch</td>
                </tr>
                <tr>
                  <td>Anna Isanina</td>
                  <td>Lecturer</td>
                  <td>a.isanina@siil.ch</td>
                </tr>
                <tr>
                  <td>Alexandra Kalgina</td>
                  <td>Associate Professor</td>
                  <td>a.kalgina@siil.ch</td>
                </tr>
                <tr>
                  <td>Anastasia Kashirina</td>
                  <td>Associate Professor</td>
                  <td>a.kashirina@siil.ch</td>
                </tr>
                <tr>
                  <td>Marina Kleymenova</td>
                  <td>Associate Professor</td>
                  <td>m.kleymenova@siil.ch</td>
                </tr>
                <tr>
                  <td>Anna Kotlova</td>
                  <td>Associate Professor</td>
                  <td>a.kotlova@siil.ch</td>
                </tr>
                <tr>
                  <td>Artem Lomakin</td>
                  <td>Lecturer</td>
                  <td>a.lomakin@siil.ch</td>
                </tr>
                <tr>
                  <td>Valentin Mingalev</td>
                  <td>Lecturer</td>
                  <td>v.mingalev@siil.ch</td>
                </tr>
                <tr>
                  <td>Elena Naumova</td>
                  <td>Lecturer</td>
                  <td>e.naumova@siil.ch</td>
                </tr>
                <tr>
                  <td>Alexander Pavlov</td>
                  <td>Lecturer</td>
                  <td>a.pavlov@siil.ch</td>
                </tr>
                <tr>
                  <td>Sonja Pravilovic</td>
                  <td>Associate Professor</td>
                  <td>s.pravilovic@siil.ch</td>
                </tr>
                <tr>
                  <td>Daria Rozhkova</td>
                  <td>Associate Professor</td>
                  <td>d.rozhkova@siil.ch</td>
                </tr>
                <tr>
                  <td>Nadezhda Rozhkova</td>
                  <td>Professor</td>
                  <td>n.rozhkova@siil.ch</td>
                </tr>
                <tr>
                  <td>Larisa Salieva</td>
                  <td>Lecturer</td>
                  <td>l.salieva@siil.ch</td>
                </tr>
                <tr>
                  <td>Sergey Sizov</td>
                  <td>Lecturer</td>
                  <td>s.sizov@siil.ch</td>
                </tr>
                <tr>
                  <td>Yulia Sizova</td>
                  <td>Associate Professor</td>
                  <td>y.sizova@siil.ch</td>
                </tr>
                <tr>
                  <td>Anna Sosnovskaya</td>
                  <td>Lecturer</td>
                  <td>a.sosnovskaya@siil.ch</td>
                </tr>
                <tr>
                  <td>Andrey Surmach</td>
                  <td>Associate Professor</td>
                  <td>a.surmach@siil.ch</td>
                </tr>
                <tr>
                  <td>Anna Talova</td>
                  <td>Lecturer</td>
                  <td>a.talova@siil.ch</td>
                </tr>
                <tr>
                  <td>Galina Tolkachenko</td>
                  <td>Professor</td>
                  <td>g.tolkachenko@siil.ch</td>
                </tr>
                <tr>
                  <td>Oksana Tolkachenko</td>
                  <td>Associate Professor</td>
                  <td>o.tolkachenko@siil.ch</td>
                </tr>
                <tr>
                  <td>Natalya Tsareva</td>
                  <td>Associate Professor</td>
                  <td>n.tsareva@siil.ch</td>
                </tr>
                <tr>
                  <td>Tatyana Vasilyuk</td>
                  <td>Associate Professor</td>
                  <td>t.vasilyuk@siil.ch</td>
                </tr>
                <tr>
                  <td>Andrey Vlasenko</td>
                  <td>Professor</td>
                  <td>a.vlasenko@siil.ch</td>
                </tr>
                <tr>
                  <td>Andrey Zaikov</td>
                  <td>Lecturer</td>
                  <td>a.zyikov@siil.ch</td>
                </tr>
                <tr>
                  <td>Polina Zakharova</td>
                  <td>Lecturer</td>
                  <td>p.zakharova@siil.ch</td>
                </tr>
                <tr>
                  <td>Ildar Zinyukov</td>
                  <td>Senior Lecturer</td>
                  <td>i.zinyukov@siil.ch</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th colSpan="3">Преподавательский состав</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Кирилл Алексеев</td>
                  <td>Доцент</td>
                  <td>k.alexeev@siil.ch</td>
                </tr>
                <tr>
                  <td>Ника Алмар</td>
                  <td>Доцент</td>
                  <td>n.almar@siil.ch</td>
                </tr>
                <tr>
                  <td>Марина Андреева</td>
                  <td>Преподаватель</td>
                  <td>m.andreeva@siil.ch</td>
                </tr>
                <tr>
                  <td>Егор Арсенихин</td>
                  <td>Преподаватель</td>
                  <td>e.arseniknin@siil.ch</td>
                </tr>
                <tr>
                  <td>Владимир Батурин</td>
                  <td>Почётный профессор</td>
                  <td>v.baturin@siil.ch</td>
                </tr>
                <tr>
                  <td>Ирина Батурина</td>
                  <td>Профессор</td>
                  <td>i.baturina@siil.ch</td>
                </tr>
                <tr>
                  <td>Татьяна Беликова</td>
                  <td>Доцент</td>
                  <td>t.belikova@siil.ch</td>
                </tr>
                <tr>
                  <td>Сергей Богачев</td>
                  <td>Профессор</td>
                  <td>s.bogachev@siil.ch</td>
                </tr>
                <tr>
                  <td>Сергей Евсеев</td>
                  <td>Преподаватель</td>
                  <td>s.evseev@siil.ch</td>
                </tr>
                <tr>
                  <td>Иван Глазков</td>
                  <td>Преподаватель</td>
                  <td>i.glazkov@siil.ch</td>
                </tr>
                <tr>
                  <td>Денис Гныря</td>
                  <td>Преподаватель</td>
                  <td>d.gnyrya@siil.ch</td>
                </tr>
                <tr>
                  <td>Андрей Ильин</td>
                  <td>Профессор</td>
                  <td>a.ilyin@siil.ch</td>
                </tr>
                <tr>
                  <td>Анна Исанина</td>
                  <td>Преподаватель</td>
                  <td>a.isanina@siil.ch</td>
                </tr>
                <tr>
                  <td>Александра Кальгина</td>
                  <td>Доцент</td>
                  <td>a.kalgina@siil.ch</td>
                </tr>
                <tr>
                  <td>Анастасия Каширина</td>
                  <td>Доцент</td>
                  <td>a.kashirina@siil.ch</td>
                </tr>
                <tr>
                  <td>Марина Клейменова</td>
                  <td>Доцент</td>
                  <td>m.kleymenova@siil.ch</td>
                </tr>
                <tr>
                  <td>Анна Котлова</td>
                  <td>Доцент</td>
                  <td>a.kotlova@siil.ch</td>
                </tr>
                <tr>
                  <td>Артем Ломакин</td>
                  <td>Преподаватель</td>
                  <td>a.lomakin@siil.ch</td>
                </tr>
                <tr>
                  <td>Валентин Мингалёв</td>
                  <td>Преподаватель</td>
                  <td>v.mingalev@siil.ch</td>
                </tr>
                <tr>
                  <td>Елена Наумова</td>
                  <td>Преподаватель</td>
                  <td>e.naumova@siil.ch</td>
                </tr>
                <tr>
                  <td>Александр Павлов</td>
                  <td>Преподаватель</td>
                  <td>a.pavlov@siil.ch</td>
                </tr>
                <tr>
                  <td>Соня Правилович</td>
                  <td>Доцент</td>
                  <td>s.pravilovic@siil.ch</td>
                </tr>
                <tr>
                  <td>Дарья Рожкова</td>
                  <td>Доцент</td>
                  <td>d.rozhkova@siil.ch</td>
                </tr>
                <tr>
                  <td>Надежда Рожкова</td>
                  <td>Профессор</td>
                  <td>n.rozhkova@siil.ch</td>
                </tr>
                <tr>
                  <td>Лариса Салиева</td>
                  <td>Преподаватель</td>
                  <td>l.salieva@siil.ch</td>
                </tr>
                <tr>
                  <td>Сергей Сизов</td>
                  <td>Преподаватель</td>
                  <td>s.sizov@siil.ch</td>
                </tr>
                <tr>
                  <td>Юлия Сизова</td>
                  <td>Доцент</td>
                  <td>y.sizova@siil.ch</td>
                </tr>
                <tr>
                  <td>Анна Сосновская</td>
                  <td>Преподаватель</td>
                  <td>a.sosnovskaya@siil.ch</td>
                </tr>
                <tr>
                  <td>Андрей Сурмач</td>
                  <td>Доцент</td>
                  <td>a.surmach@siil.ch</td>
                </tr>
                <tr>
                  <td>Анна Талова</td>
                  <td>Преподаватель</td>
                  <td>a.talova@siil.ch</td>
                </tr>
                <tr>
                  <td>Галина Толкаченко</td>
                  <td>Профессор</td>
                  <td>g.tolkachenko@siil.ch</td>
                </tr>
                <tr>
                  <td>Оксана Толкаченко</td>
                  <td>Доцент</td>
                  <td>o.tolkachenko@siil.ch</td>
                </tr>
                <tr>
                  <td>Наталья Царёва</td>
                  <td>Доцент</td>
                  <td>n.tsareva@siil.ch</td>
                </tr>
                <tr>
                  <td>Татьяна Василюк</td>
                  <td>Доцент</td>
                  <td>t.vasilyuk@siil.ch</td>
                </tr>

                <tr>
                  <td>Андрей Власенко</td>
                  <td>Профессор</td>
                  <td>a.vlasenko@siil.ch</td>
                </tr>
                <tr>
                  <td>Андрей Зайков</td>
                  <td>Преподаватель</td>
                  <td>a.zyikov@siil.ch</td>
                </tr>
                <tr>
                  <td>Полина Захарова</td>
                  <td>Преподаватель</td>
                  <td>p.zakharova@siil.ch</td>
                </tr>
                <tr>
                  <td>Ильдар Зинюков</td>
                  <td>Старший преподаватель</td>
                  <td>i.zinyukov@siil.ch</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModuleLeads;
