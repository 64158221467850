import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { fetchProgramsDegrees } from "../../actions/programs";

import { MENU_OPEN_CLASS, NAVIGATION } from "../../constants/global";
import { URL } from "../../config/config";
import { isLoggedIn } from "../../actions/global";

import { LogoIcon, UserIcon, SearchIcon } from "../utils/svg";
import { LogoTextIcon } from "../utils/logo-text";
import { languagePathUrl } from "../utils/languagePathUrl";
import { useTranslation } from "react-i18next";
import HeaderLanguages from "./HeaderLanguages";
import HeaderNotice from "./HeaderNotice";
import { getNoticeLocale } from "../../actions/global";
import i18next from "i18next";
import SwitchTheme from "../shared/Switch";
import SwitchAccessibility from "../shared/SwitchAccessibility";
import Accessibility from "./Accessibility";
import { fetchAccessibility, getAccessibilitySize } from "../../actions/global";

import { Form, Input } from "antd";

function Header() {
  const dispatch = useDispatch();
  const loggedIn = isLoggedIn();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Search } = Input;
  const [isNavToggled, toggleNav] = useState(false);
  const { t } = useTranslation();
  const { programsDegrees, programsDegreesAreLoading } = useSelector((state) => state.programs);
  const { currentLanguage } = useSelector((state) => state.global);
  const { accessibility } = useSelector((state) => state.global);

  let locale = i18next.language;
  let noticeLocale = getNoticeLocale();

  let languageBrowser = window.navigator ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage : "en";
  languageBrowser = languageBrowser.substr(0, 2).toLowerCase();

  useEffect(() => {
    isNavToggled ? document.body.classList.add(MENU_OPEN_CLASS) : document.body.classList.remove(MENU_OPEN_CLASS);
  }, [isNavToggled]);

  useEffect(() => {
    dispatch(fetchProgramsDegrees({ locale: locale }));
  }, [dispatch, locale]);

  useEffect(() => {
    if (getAccessibilitySize() && !accessibility) {
      dispatch(fetchAccessibility(!accessibility));
    }
  }, [dispatch]);

  const toToggleNav = () => toggleNav(!isNavToggled);

  const onSearch = () => {
    form.submit();
  };

  const onFinish = (values) => {
    form.resetFields();
    toggleNav(!isNavToggled);
    navigate(`${languagePathUrl(currentLanguage)}/search#gsc.q=${values.headerSearch}`);
  };

  const renderNavLink = (url, name) =>
    url ? (
      <NavLink to={`${languagePathUrl(currentLanguage)}${url}`} data-key={name}>
        {t(name)}
      </NavLink>
    ) : (
      <span data-key={name}>{t(name)}</span>
    );

  return (
    <>
      {locale !== languageBrowser && noticeLocale !== "no" && <HeaderNotice lang={languageBrowser} />}
      {accessibility && <Accessibility />}

      <div className="header-section">
        <header className="header">
          <div className="header__backdrop" onClick={toToggleNav}></div>

          <Link className="header__logo" to={`${languagePathUrl(locale)}/`}>
            <i className="header__logo-icon header__logo-icon_mobile">
              <LogoIcon />
            </i>
            <i className="header__logo-icon">
              <LogoTextIcon />
            </i>
          </Link>

          <div className="header__buttons">
            <HeaderLanguages />

            <a className="header__btn" href={!loggedIn ? `${URL}/sign_in` : `${URL}/profile`}>
              <div className="header__btn-icon">
                <UserIcon />
              </div>
              <div className="header__btn-label" data-key="ui.account" data-key-login="ui.login">
                {loggedIn ? `${t("ui.account")}` : `${t("ui.login")}`}
              </div>
            </a>

            <div className="header__btn header__burger" onClick={toToggleNav}>
              <div className="header__btn-icon header__burger-icon">
                <span></span>
              </div>
              <div className="header__btn-label header__burger-label" data-key="ui.menu">
                {t("ui.menu")}
              </div>
            </div>
          </div>

          <div className="header__nav-wrap">
            <div className="header__search-wrap">
              <Form colon={false} onFinish={onFinish} autoComplete="off" name="header-search" form={form} className="form header__search">
                <Form.Item
                  name="headerSearch"
                  data-key="header.search_placeholder"
                  data-key-error="header.search.empty_field"
                  rules={[
                    {
                      required: true,
                      message: t("header.search.empty_field"),
                    },
                  ]}
                  validateTrigger="onSubmit"
                >
                  <Search placeholder={t("header.search_placeholder")} className="form__search-field header__search-field" allowClear onSearch={onSearch} enterButton={<SearchIcon />} />
                </Form.Item>
              </Form>
            </div>
            {!programsDegreesAreLoading && programsDegrees.length > 0 ? (
              <nav className="header__nav">
                <div className="header__nav-title" data-key="ui.degrees">
                  {t("ui.degrees")}
                </div>
                {programsDegrees.map((item, ikey) => (
                  <div className="header__nav-item" key={ikey}>
                    <NavLink to={`${languagePathUrl(currentLanguage)}/programs/${item.attributes.slug}`} onClick={() => toggleNav(!isNavToggled)}>
                      {item.attributes.title}
                    </NavLink>
                  </div>
                ))}
              </nav>
            ) : null}

            <nav className="header__nav">
              <div className="header__nav-title" data-key="ui.account">
                {t("ui.account")}
              </div>
              {loggedIn ? (
                <>
                  <div className="header__nav-item">
                    <a href={`${URL}/profile`} data-key="ui.account">
                      {t("ui.account")}
                    </a>
                  </div>
                  <div className="header__nav-item">
                    <a href={`${URL}/sign_out`} data-key="ui.logout">
                      {t("ui.logout")}
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="header__nav-item">
                    <a href={`${URL}/register`} data-key="ui.signup">
                      {t("ui.signup")}
                    </a>
                  </div>
                  <div className="header__nav-item">
                    <a href={`${URL}/sign_in`} data-key="ui.login">
                      {t("ui.login")}
                    </a>
                  </div>
                </>
              )}
            </nav>

            {NAVIGATION.map(({ name, url, submenu }, ikey) => (
              <nav className="header__nav" key={ikey}>
                <div className="header__nav-title">{renderNavLink(url, name)}</div>
                {submenu &&
                  submenu.length > 0 &&
                  submenu.map(({ name, url, submenu }, key) => (
                    <div key={key}>
                      <div className="header__nav-item">{renderNavLink(url, name)}</div>
                      <div className="header__nav-submenu">
                        {submenu &&
                          submenu.length > 0 &&
                          submenu.map(({ name, url, submenu }, key) => (
                            <div className="header__nav-item" key={key}>
                              {renderNavLink(url, name)}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </nav>
            ))}

            <nav className="header__nav">
              <div className="header__nav-title" data-key="ui.dark_theme">
                {t("ui.dark_theme")}
              </div>
              <SwitchTheme />
            </nav>
            <nav className="header__nav">
              <div className="header__nav-title" data-key="ui.accessibility.menu_title">
                {t("ui.accessibility.menu_title")}
              </div>
              <SwitchAccessibility />
            </nav>
          </div>
        </header>
      </div>
    </>
  );
}

export default Header;
