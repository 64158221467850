import MainLayout from "../layout";
import { LinkDecor } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function AwardsAndAccreditations() {
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.awards_and_accreditations.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.awards_and_accreditations.title")} titleKey="pages.awards_and_accreditations.title">
        <LinkDecor link="/docs/914b/Swiss_International_Institute_of_Lausanne_IA__2_.pdf">
          {locale !== "ru" ? "International Institutional Accreditation" : "Международная институциональная аккредитация"}
        </LinkDecor>
        <LinkDecor link="/docs/93b6/20690142_Swiss_International_Institute_Lausanne_Europe__Middle_East.pdf">
          {locale !== "ru" ? "QS Online MBA Rankings 2024: Europe, Middle East and Africa" : "QS Рейтинг Online MBA 2024: Европа, Ближний Восток и Африка"}
        </LinkDecor>
        <LinkDecor link="/docs/93b7/20690142_-_Swiss_International_Institute_Lausanne__Global_.pdf">
          {locale !== "ru" ? "QS Online MBA Rankings 2024: Global" : "QS Рейтинг Online MBA 2024: Глобальный"}
        </LinkDecor>

        <LinkDecor link="/docs/8cad/EduQua_Certificate_21742_2022_en__2_.pdf">eduQua Certificate</LinkDecor>
        <LinkDecor link="/docs/8d1a/Online_Learning_Stand-alone_audit_Swiss_International_Institute_Lausanne_certificate_2022.pdf">QS Five Stars Certificate</LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default AwardsAndAccreditations;
