import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setRefId, setGetParam } from "../actions/global";
import PageNotFound from "./PageNotFound";

import Home from "./home/Home";
import ProgramsList from "./programs/ProgramList";
import ProgramPage from "./programs/ProgramPage";
// import About from "./about/About";
import Faculties from "./faculties/Faculties";
import Posts from "./posts/Posts";
import PostPage from "./posts/PostPage";
import SearchPage from "./search/SearchPage";
import Diplomas from "./diplomas/Diplomas";
// import Documents from "./documents/Documents";
import Faq from "./faq/Faq";
import PartnersList from "./partners/PartnersList";
import PartnerPage from "./partners/PartnerPage";
import Agreement from "./agreement/Agreement";
import Contacts from "./contacts/Contacts";
import Presskit from "./presskit/Presskit";
import Reviews from "./reviews/Reviews";

import StudentServices from "./studentServices/StudentServices";
import Admissions from "./admissions/Admissions";
import Academic from "./academic/Academic";
import CareerAdviceTeam from "./careerAdviceTeam/CareerAdviceTeam";
import LearningSupport from "./learningSupport/LearningSupport";
import ELearningPlatform from "./eLearningPlatform/ELearningPlatform";

import AlumniNetwork from "./alumniNetwork/AlumniNetwork";
import Impressum from "./impressum/Impressum";
import Institute from "./institute/Institute";
import TeachingStaff from "./teachingStaff/TeachingStaff";
import ProgramDirectors from "./programDirectors/ProgramDirectors";
import ITFaculty from "./ITFaculty/ITFaculty";
import ManagementFaculty from "./managementFaculty/ManagementFaculty";
import QualityAssuranceOffice from "./qualityAssuranceOffice/QualityAssuranceOffice";
// import ITSecurity from "./ITSecurity/ITSecurity";
import Policies from "./policies/Policies";
import StrategicPlans from "./strategicPlans/StrategicPlans";
import AnnualReports from "./annualReports/AnnualReports";
import StatutoryDocuments from "./statutoryDocuments/StatutoryDocuments";
import GeneralInformation from "./generalInformation/GeneralInformation";
import AwardsAndAccreditations from "./awardsAndAccreditations/AwardsAndAccreditations";
import StudyPlans from "./studyPlans/StudyPlans";
import ImportantInformationStudents from "./importantInformationStudents/ImportantInformationStudents";
import Community from "./community/Community";
import StudySIIL from "./studySIIL/StudySIIL";
import Disclaimer from "./disclaimer/Disclaimer";

import ELibrary from "./eLibrary/ELibrary";

import ScrollToTop from "./utils/ScrollToTop";
import { languagePathUrl } from "./utils/languagePathUrl";
import i18n from "i18next";

export default function Main() {
  let location = useLocation();
  let locale = location.state !== null ? location.state.locale : i18n.language;
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("refid");
    const refP = searchParams.get("refp");
    const refPr = searchParams.get("refpr");

    if (token) {
      setRefId(token);
    }

    if (refP) {
      setGetParam("refp", refP);
    }

    if (refPr) {
      setGetParam("refpr", refPr);
    }
  }, []);

  useEffect(() => {
    if (window.location.search.indexOf("utm_") > -1) {
      setGetParam("referer", window.location.href);
    }

    if (location.search.startsWith("?roistat_visit")) {
      setGetParam("roistat_visit", searchParams.get("roistat_visit"));
    }

    if (locale !== "en" && !location.pathname.startsWith(`/${locale}`)) {
      if (locale === "zh-CN" && location.pathname.startsWith(`/cn`)) {
        return;
      }

      navigate({
        pathname: `${languagePathUrl(locale)}${location.pathname}`,
      });
    }
  }, []);

  return (
    <div className="app__wrapper">
      <ScrollToTop>
        <Routes>
          <Route path={languagePathUrl(locale)}>
            <Route index element={<Home />} />
            <Route path="r/:slug" element={<Home />} />
            <Route path="programs" element={<ProgramsList />}>
              <Route path=":slug" element={<ProgramsList />} />
            </Route>
            <Route path="programs/:slug/:id" element={<ProgramPage />} />
            {/* <Route path="about-us" element={<About />} /> */}
            <Route path="faculties" element={<Faculties />} />
            <Route path="blog" element={<Posts />}>
              <Route path=":slug" element={<Posts />}></Route>
            </Route>
            <Route path="blog/:slug/:titleSlug" element={<PostPage />} />
            <Route path="documents" element={<Diplomas />} />
            {/* <Route path="dokumenty" element={<Documents />} /> */}
            <Route path="faq" element={<Faq />} />
            <Route path="partners">
              <Route path="" element={<PartnersList />} />
              <Route path=":slug" element={<PartnerPage />} />
            </Route>
            <Route path="agreement" element={<Agreement />} />
            <Route path="impressum" element={<Impressum />} />
            <Route path="disclaimer" element={<Disclaimer />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="presskit" element={<Presskit />} />
            <Route path="reviews" element={<Reviews />} />

            <Route path="study-siil" element={<StudySIIL />} />

            <Route path="student-services" element={<StudentServices />} />
            <Route path="student-services/admissions-and-records-team" element={<Admissions />} />
            <Route path="student-services/academic-assistance-team" element={<Academic />} />
            <Route path="student-services/career-advice-team" element={<CareerAdviceTeam />} />
            <Route path="student-services/learning-support-team" element={<LearningSupport />} />

            <Route path="e-learning-platform" element={<ELearningPlatform />} />
            <Route path="e-library" element={<ELibrary />} />

            <Route path="alumni" element={<AlumniNetwork />} />
            <Route path="search" element={<SearchPage />} />
            <Route path="institute" element={<Institute />} />

            <Route path="teaching-staff" element={<TeachingStaff />} />

            <Route path="teaching-staff/program-directors" element={<ProgramDirectors />} />
            <Route path="program-directors" element={<ProgramDirectors />} />

            <Route path="it" element={<ITFaculty />} />
            <Route path="management-faculty" element={<ManagementFaculty />} />

            <Route path="quality-assurance-office" element={<QualityAssuranceOffice />} />
            {/* <Route path="it-security" element={<ITSecurity />} /> */}
            <Route path="policies" element={<Policies />} />
            <Route path="strategic-plans" element={<StrategicPlans />} />
            <Route path="annual-reports" element={<AnnualReports />} />
            <Route path="statutory-documents" element={<StatutoryDocuments />} />
            <Route path="general-information" element={<GeneralInformation />} />
            <Route path="awards-and-accreditations" element={<AwardsAndAccreditations />} />
            <Route path="study-plans" element={<StudyPlans />} />
            <Route path="important-information-for-students" element={<ImportantInformationStudents />} />
            <Route path="community" element={<Community />} />

            <Route path="*" element={<PageNotFound needLayout={true} />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </div>
  );
}
