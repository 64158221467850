import { useState } from "react";
import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { SliderHorizontalIcon } from "../utils/svg";
import { Section } from "@vshp/ui-components";
import i18n from "i18next";

function ProgramDirectors() {
  const { t } = useTranslation();
  const [backdropToggled, setBackdropToggled] = useState(true);
  let locale = i18n.language;

  let title = {
    caption: t("pages.program_directors.title"),
  };

  const handleBackdropClick = (e) => {
    setBackdropToggled(false);
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.program_directors.title")} titleKey="pages.program_directors.title">
        <Section>
          <div className="stat__table">
            {backdropToggled && (
              <>
                <div className="stat__table-icon">
                  <SliderHorizontalIcon />
                </div>
                <div className="stat__table-backdrop" onClick={handleBackdropClick}></div>
              </>
            )}

            <div className="stat__table-data">
              <div className="stat__table-data-container">
                {locale !== "ru" ? (
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="4">
                          <strong>Faculty of Management</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Dean</strong>
                        </td>
                        <td> Daria Rozhkova </td>
                        <td> Associate Professor </td>
                        <td> d.rozhkova@siil.ch </td>
                      </tr>

                      <tr>
                        <td colSpan="4">
                          <strong>Study Programme Directors</strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Bachelor in Event Management</td>
                        <td> Andrey Ilyin </td>
                        <td> Professor </td>
                        <td> a.ilyin@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Bachelor in Project Management</td>
                        <td> Daria Rozhkova </td>
                        <td> Associate Professor </td>
                        <td> d.rozhkova@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Bachelor in Luxury Brand Management</td>
                        <td> Nika Almar </td>
                        <td> Associate Professor </td>
                        <td> n.almar@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Master in International Relations</td>
                        <td> Yulia Sizova </td>
                        <td> Associate Professor </td>
                        <td>y.sizova@siil.ch</td>
                      </tr>
                      <tr>
                        <td> MBA RU </td>
                        <td> Galina Tolkachenko </td>
                        <td> Professor </td>
                        <td> g.tolkachenko@siil.ch </td>
                      </tr>
                      <tr>
                        <td> MBA EN </td>
                        <td> Kevin Russel Smith </td>
                        <td> Associate Professor </td>
                        <td> k.smith@siil.ch </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="4">
                          <strong>Факультет менеджмента</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Декан факультета</strong>
                        </td>
                        <td> Дарья Рожкова</td>
                        <td> Доцент </td>
                        <td> d.rozhkova@siil.ch </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <strong>Директора учебных программ </strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Бакалавриат «Event-менеджмент» </td>
                        <td> Андрей Ильин </td>
                        <td> Профессор </td>
                        <td> a.ilyin@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Бакалавриат «Проектный менеджмент» </td>
                        <td> Дарья Рожкова </td>
                        <td> Доцент </td>
                        <td> d.rozhkova@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Бакалавриат «Бренд-менеджмент товаров и услуг класса люкс»</td>
                        <td> Ника Алмар </td>
                        <td> Доцент </td>
                        <td> n.almar@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Магистратура «Магистр международных отношений»</td>
                        <td> Юлия Сизова</td>
                        <td> Доцент </td>
                        <td>y.sizova@siil.ch</td>
                      </tr>
                      <tr>
                        <td> Магистратура «Магистр делового администрирования»</td>
                        <td> Галина Толкаченко </td>
                        <td> Профессор </td>
                        <td> g.tolkachenko@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Магистратура «Магистр делового администрирования в управлении бизнесом» - на английском</td>
                        <td> Кевин Рассел Смит </td>
                        <td> Доцент </td>
                        <td> k.smith@siil.ch </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <div className="stat__table">
            {backdropToggled && (
              <>
                <div className="stat__table-icon">
                  <SliderHorizontalIcon />
                </div>
                <div className="stat__table-backdrop" onClick={handleBackdropClick}></div>
              </>
            )}

            <div className="stat__table-data">
              <div className="stat__table-data-container">
                {locale !== "ru" ? (
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="4">IT Faculty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Dean</strong>
                        </td>
                        <td> Pavel Tkachev</td>
                        <td> Associate Professor </td>
                        <td> p.tkachev@siil.ch</td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <strong>Study Programme Directors</strong>
                        </td>
                      </tr>
                      <tr>
                        <td> Bachelor in Information Systems and Technology</td>
                        <td>Pavel Tkachev</td>
                        <td>Associate Professor</td>
                        <td> p.tkachev@siil.ch</td>
                      </tr>
                      <tr>
                        <td> Bachelor in Internet Marketing</td>
                        <td>Anastasia Kashirina</td>
                        <td>Associate Professor</td>
                        <td> a.kashirina@siil.ch</td>
                      </tr>
                      <tr>
                        <td>Bachelor in Web Design</td>
                        <td>Kirill Alexeev</td>
                        <td>Associate Professor</td>
                        <td> k.alexeev@siil.ch</td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="4">Факультет информационных систем и технологий</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Декан факультета</strong>
                        </td>
                        <td> Павел Ткачев </td>
                        <td> Доцент </td>
                        <td> p.tkachev@siil.ch </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <strong>Директора учебных программ </strong>
                        </td>
                      </tr>

                      <tr>
                        <td> Бакалавриат «Информационные системы и технологии» </td>
                        <td> Павел Ткачев </td>
                        <td> Доцент </td>
                        <td> p.tkachev@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Бакалавриат «Интернет-маркетинг» </td>
                        <td> Анастасия Каширина </td>
                        <td> Доцент </td>
                        <td> a.kashirina@siil.ch </td>
                      </tr>
                      <tr>
                        <td> Бакалавриат «Веб-дизайн» </td>
                        <td> Кирилл Алексеев </td>
                        <td> Доцент </td>
                        <td> k.alexeev@siil.ch </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </Section>
      </Page>
    </MainLayout>
  );
}

export default ProgramDirectors;
