import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EyeIcon } from "../utils/svg";
import { ACCESSIBILITY_SIZE_CLASS, ACCESSIBILITY_COLOR_CLASS, ACCESSIBILITY_CONTENT } from "../../constants/global";
import {
  fetchAccessibility,
  fetchAccessibilityActiveSize,
  fetchAccessibilityActiveColor,
  setAccessibilitySize,
  setAccessibilityColor,
  getAccessibilitySize,
  getAccessibilityColor,
  removeAccessibilitySize,
  removeAccessibilityColor,
} from "../../actions/global";

function Accessibility() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { accessibility, accessibilityActiveColor, accessibilityActiveSize } = useSelector((state) => state.global);

  const [activeSize, toggleActiveSize] = useState(getAccessibilitySize() === null ? accessibilityActiveSize : parseInt(getAccessibilitySize(), 10));
  const [prevActiveSize, togglePrevActiveSize] = useState(activeSize);

  const [activeColor, toggleActiveColor] = useState(getAccessibilityColor() === null ? accessibilityActiveColor : parseInt(getAccessibilityColor(), 10));
  const [prevActiveColor, togglePrevActiveColor] = useState(activeColor);

  useEffect(() => {
    document.body.classList.remove(`${ACCESSIBILITY_SIZE_CLASS + prevActiveSize}`);
    document.body.classList.add(`${ACCESSIBILITY_SIZE_CLASS + (activeSize + 1)}`);
    setAccessibilitySize(activeSize);
  }, [activeSize]);

  useEffect(() => {
    document.body.classList.remove(`${ACCESSIBILITY_COLOR_CLASS + prevActiveColor}`);
    document.body.classList.add(`${ACCESSIBILITY_COLOR_CLASS + (activeColor + 1)}`);
    setAccessibilityColor(activeColor);
  }, [activeColor]);

  const handleAccessibility = (e) => {
    e.preventDefault();
    removeAccessibilitySize();
    removeAccessibilityColor();
    dispatch(fetchAccessibility(!accessibility));
  };

  const handleAccessibilityBtn = (id, num, togglePrev, toggleActive, fetchState) => () => {
    togglePrev(num + 1);
    toggleActive(id);
    dispatch(fetchState(id));
  };

  const renderClasses = (id, uniqClass, num) =>
    cn("accessibility__btn", `${uniqClass}${id + 1}`, {
      active: id === parseInt(num, 10),
    });

  return (
    <div className="accessibility">
      <Link className="accessibility-col" href="/accessibility" onClick={handleAccessibility}>
        <div className="accessibility__btn-icon">
          <EyeIcon />
        </div>
        <div className="accessibility__btn-label accessibility__btn-back" data-key="ui.accessibility.go_back">
          {t("ui.accessibility.go_back")}
        </div>
      </Link>
      <div className="accessibility-col">
        <div className="accessibility__btn-label" data-key="ui.accessibility.font_size">
          {t("ui.accessibility.font_size")}
        </div>
        {ACCESSIBILITY_CONTENT.map((item, id) => (
          <div
            key={id}
            className={renderClasses(id, "accessibility__btn_size", activeSize)}
            onClick={handleAccessibilityBtn(id, activeSize, togglePrevActiveSize, toggleActiveSize, fetchAccessibilityActiveSize)}
          >
            {item}
          </div>
        ))}
      </div>

      <div className="accessibility-col">
        <div className="accessibility__btn-label" data-key="ui.accessibility.color_scheme">
          {t("ui.accessibility.color_scheme")}
        </div>
        {ACCESSIBILITY_CONTENT.map((item, id) => (
          <div
            key={id}
            className={renderClasses(id, "accessibility__btn_color", activeColor)}
            onClick={handleAccessibilityBtn(id, activeColor, togglePrevActiveColor, toggleActiveColor, fetchAccessibilityActiveColor)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Accessibility;
